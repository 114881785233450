import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { Layout } from "../components/Layout";
import { Head } from "../components/Head";

import {
  SectionContainer,
  SectionContent,
  SectionWrapper
} from "../common/Section";

import { AboutFlexbox, BenefitsFlexbox, TermitesFlexbox } from "../components/Flexbox";
import { CallToAction } from "../components/CallToAction";
import { PestCard } from "../components/Card";
import { CommonPestsGrid } from "../components/Grid";
import { IndexHero } from "../components/Hero";
import { ServiceCarousel, TestimonialsCarousel } from "../components/Carousel";


interface Props {};


const IndexPage: React.FC<Props> = () => {
  const data: any = useStaticQuery(graphql`
    query {
      homepage: mdx(
        frontmatter: {
          title: {
            eq: "Home"
          }
        }
      ) {
        frontmatter {
          heroImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          benefitsImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          aboutImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          termitesImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }

      benefits: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "benefit"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: DESC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              description
              icon
              template
            }
          }
        }
      }

      pests: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "pest"
            }
            featured: {
              eq: true
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            frontmatter {
              heading
              image {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      services: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "service"
            }
          }
        }
        sort: {
          fields: [frontmatter___order]
          order: ASC
        }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              cardHeading
              cardDescription
              heroImage {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }

      testimonials: allMdx(
        filter: {
          frontmatter: {
            template: {
              eq: "testimonial"
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              source
              reviewer
              review
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Head
        title="Home"
      />

      <SectionWrapper
        background="background.linen"
      >
        <SectionContainer>
          <IndexHero
            heroImage={ data.homepage.frontmatter.heroImage.childImageSharp.gatsbyImageData }
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="78px 0"
      >
        <BenefitsFlexbox
          heading="That Peace of Mind Solution You are Looking For."
          description={[
            "Whether you need an inspection, treatment or preventative service, Vester Pest Control is here to help."
          ]}
          image={ data.homepage.frontmatter.benefitsImage.childImageSharp.gatsbyImageData }
          nodes={ data.benefits.edges }
        />
      </SectionWrapper>

      <SectionWrapper>
        <SectionContainer>
          <AboutFlexbox
            direction="row"
            heading="About Vester Pest Control"
            description={[
              "Vester Pest Control has been servicing San Diego County for over 20 years. Our company is committed to the full protection of our clients, their health, and their property by promoting safety and environmental consciousness, by taking the time to screen and train our service technicians and by creating customized solutions to your pest control needs.",
              "Our job is to get your 100% service satisfaction. At Vester Pest Control, our work is also insured and warrantied."
            ]}
            image={ data.homepage.frontmatter.aboutImage.childImageSharp.gatsbyImageData }
            imageMaxW={{
              base: '50%',
              sm: '320px',
              md: '100%',
              lg: '200px',
              xl: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="linear-gradient(white 0%, white 57%, #F94E1911 57%, #F94E1911 100%)"
        padding="64px 0 57px"
      >
        <SectionContainer>
          <CommonPestsGrid
            heading="Common Pests"
            description={[
              "At Vester, we know our pests, and you can too. Explore our pest library to learn how to identify common pests."
            ]}
          >
            { data.pests.edges.map(( pest: any, _index: number ) => (
              <PestCard
                heading={ pest.node.frontmatter.heading }
                image={ pest.node.frontmatter.image.childImageSharp.gatsbyImageData }
              />
            ))}
          </CommonPestsGrid>
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        background="background.linkwater"
        padding="155px 0"
      >
        <SectionContainer>
          <TermitesFlexbox
            direction="row"
            heading="Complete Termite Extermination"
            description={[
              "Vester Pest Control is the number one choice for San Diego County termite inspections and termite treatments. We have over 20 years of combined experience and have helped over 20,000 homeowners and businesses with their termite infestations and other pest control needs.",
              "Contact us directly to receive your specialized termite and wood-destroying organisms estimate."
            ]}
            image={ data.homepage.frontmatter.termitesImage.childImageSharp.gatsbyImageData }
            imageBackground="white"
            imageMaxW={{
              base: '80%',
              sm: '320px',
              md: '100%'
            }}
          />
        </SectionContainer>
      </SectionWrapper>

      <SectionWrapper
        padding="50px 0 39px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            padding="0 1rem"
            textAlign="center"
            heading="Our Services"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            description={[
              "Whether you need an inspection, treatment or preventative service, Vester Pest Control is here to help."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '500px'
            }}
          />
        </SectionContainer>
        <ServiceCarousel
          nodes={ data.services.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="80px 0 46px"
      >
        <SectionContainer>
          <SectionContent
            justifyContent="flex-start"
            alignItems="center"
            textAlign="center"
            heading="Our service and professionals have earned over 3,700+ reviews!"
            headingWidth={{
              base: '100%',
              lg: '700px'
            }}
            headingMargin="0 auto"
            description={[
              "Vester Pest Control has helped thousands of property owners over the past 20 years. Here are what some of our customers have to say."
            ]}
            descriptionWidth={{
              base: '100%',
              sm: '580px'
            }}
          />
        </SectionContainer>
        <TestimonialsCarousel
          nodes={ data.testimonials.edges }
        />
      </SectionWrapper>

      <SectionWrapper
        padding="60px 0"
      >
        <SectionContainer>
          <CallToAction
            heading="Request a Free Quote"
          />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default IndexPage;
